// Open Sans
@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700';

// wri-icons
@font-face {
  font-family: wri-icons;
  font-style: normal;
  font-display: block;
  text-rendering: optimizelegibility;
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/htkvjtfz98m3r6kg8mwz2v73/wri-icons-2047.otf')
      format('opentype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/wg92t3mx9xsmks77jrcpm45/wri-icons-2047.woff')
      format('woff'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/jx8k6rs9h559pft9r8v9tckk/wri-icons-2047.ttf')
      format('truetype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/jzwg83753nmwrhptmqnzbvr5/wri-icons-2047.svg')
      format('svg');
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/r3b5k7qt9gc6w49jcrqvvx/RocGroteskCond-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rmj7gvg8wkbrg8s87z2rszjc/RocGroteskCond-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rhfv6znz9ntcxs9vv3963j/RocGroteskCond-ExtraBold.otf');
  font-weight: 700;
}
